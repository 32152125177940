jQuery( document ).ready(function($) {
    $(function () {
        $('[data-toggle="popover"]').popover()
    });

    $('[data-toggle="tooltip"]').tooltip().addClass('small_underline');

    $('#tab-navigation a').click(function (e) {
        e.preventDefault()
        $(this).tab('show')
        if(!$(this).hasClass('toc-show')) {
            $('.toc').css('display', 'none');
        } else {
            $('.toc').css('display', 'block');
        }
    });

    // jQuery('#back-to-top').click(function(event) {
    //     event.preventDefault();
    //     jQuery('html, body').animate({scrollTop: 0}, 200);
    //     return false;
    // });
    //
    // $('.toc-wrapper').find(':header').attr('data-toc-include', '');
    //
    // jQuery('nav[data-toggle="toc"]').each(function(e, t) {
    //     var r = jQuery(t);
    //     Toc.init(r)
    // });

    $('.cc-open').click(function(){
        showCookieChoice();
    });

    $('.organisation-slider').owlCarousel({
        margin:40,
        nav:true,
        autoplay:true,
        autoplayTimeout:5000,
        autoplayHoverPause:true,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:3
            },
            1000:{
                items:4
            }
        }
    });
    // JQuery logic to handle showing and hiding of storyline filters.
    $('.filter-expand').click(function (e) {
        e.preventDefault();
        var nav = $('.sidenav');
        var navwrapper = $('#sidenav-wrapper');
        if(nav.hasClass('out')){
            nav.removeClass('out');
            navwrapper.addClass('d-none');
            $('#expand-fa').removeClass('fa-times').addClass('fa-plus');
            $('#filter-expand-content').removeClass('d-none');
        } else {
            nav.addClass('out');
            navwrapper.removeClass('d-none');
            $('#expand-fa').removeClass('fa-plus').addClass('fa-times');
            $('#filter-expand-content').addClass('d-none');
        }
    });

    // General fucntion for getting a cookie through javascript, since all are returned as a single string with ```document.cookie```.
    // Used here for retrieving IE notification cookie.
    function getCookie(name) {
        return (name = (document.cookie + ';').match(new RegExp(name + '=.*;'))) && name[0].split(/=|;/)[1];
    }
    // Check if browser is IE based on the userAgent. The specific version is not as relevant. Edge is not seen as IE.
    function isIE() {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");
        return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
    }
    // Set cookie to store that user has been notified of their IE usage and has clicked it away.
    function setIENotified() {
        document.cookie = "IENotified=true";
    }
    // Get status of IE notifcation. Returns true if user has already been notified.
    function getIENotified() {
        var notified = getCookie('IENotified');
        return !(notified == null || notified == 'false');
    }
    // Determine whether to show the IE pop up modal.
    if(isIE() && !getIENotified()) {
        $('#IEModal').modal('show');
        $('#IEModal').on('hide.bs.modal', function (e) {
            setIENotified();
        });
    }

});
