/*!
 * Bootstrap Table of Contents v0.4.1 (http://afeld.github.io/bootstrap-toc/)
 * Copyright 2015 Aidan Feldman
 * Licensed under MIT (https://github.com/afeld/bootstrap-toc/blob/gh-pages/LICENSE.md) */
! function() {
    "use strict";
    window.Toc = {
        helpers: {
            findOrFilter: function(e, t) {
                var r = e.find(t);
                return e.filter(t).add(r).filter("[data-toc-include]")
            },
            generateUniqueIdBase: function(e) {
                var t = jQuery(e).text(),
                    r = t.trim().toLowerCase().replace(/[^A-Za-z0-9]+/g, "-");
                return r || e.tagName.toLowerCase()
            },
            generateUniqueId: function(e) {
                for (var t = this.generateUniqueIdBase(e), r = 0;; r++) {
                    var n = t;
                    if (r > 0 && (n += "-" + r), !document.getElementById(n)) return n
                }
            },
            generateAnchor: function(e) {
                if (e.id) return e.id;
                var t = this.generateUniqueId(e);
                return e.id = t, t
            },
            createNavList: function() {
                return jQuery('<ul class="nav"></ul>')
            },
            createChildNavList: function(e) {
                var t = this.createNavList();
                return e.append(t), t
            },
            generateNavEl: function(e, t) {
                var r = jQuery("<a></a>");
                r.attr("href", "#" + e), r.text(t);
                var n = jQuery("<li></li>");
                return n.append(r), n
            },
            generateNavItem: function(e) {
                var t = this.generateAnchor(e),
                    r = jQuery(e),
                    n = r.data("toc-text") || r.text();
                return this.generateNavEl(t, n)
            },
            getTopLevel: function(e) {
                for (var t = 1; t <= 6; t++) {
                    var r = this.findOrFilter(e, "h" + t);
                    if (r.length > 1) return t
                }
                return 1
            },
            getHeadings: function(e, t) {
                var r = "h" + t,
                    n = t + 1,
                    a = "h" + n;
                return this.findOrFilter(e, r + "," + a)
            },
            getNavLevel: function(e) {
                return parseInt(e.tagName.charAt(1), 10)
            },
            populateNav: function(e, t, r) {
                var n, a = e,
                    i = this;
                r.each(function(r, o) {
                    var s = i.generateNavItem(o),
                        u = i.getNavLevel(o);
                    u === t ? a = e : n && a === e && (a = i.createChildNavList(n)), a.append(s), n = s
                })
            },
            parseOps: function(e) {
                var t;
                return t = e.jquery ? {
                    jQuerynav: e
                } : e, t.jQueryscope = t.jQueryscope || jQuery(document.body), t
            }
        },
        init: function(e) {
            e = this.helpers.parseOps(e), e.jQuerynav.attr("data-toggle", "toc");
            var t = this.helpers.createChildNavList(e.jQuerynav),
                r = this.helpers.getTopLevel(e.jQueryscope),
                n = this.helpers.getHeadings(e.jQueryscope, r);
            this.helpers.populateNav(t, r, n)
        }
    }, jQuery(function() {

    })
}();